import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				category: "статус заказов",
				title: "Наведи порядок",
				description:
					"<p>Сотрудникам не нужно вычислять обладателя ванильного капучино: особенно пригодится в пиковые часы</p>",
				mediaContent: (
					<div className={styles.orderImageWrapper}>
						<StaticImage
							className={styles.orderImage}
							src="./assets/order-feature.png"
							alt="экран очереди"
							breakpoints={[320, 700, 810, 568]}
							sizes="(max-width: 520px) 300px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				className: styles.orderFeature,
				theme: "primary",
			},
			{
				category: "место для рекламы",
				title: "Предложи ещё кое-что",
				description:
					"<p>На экране хватит места для карусели из нескольких баннеров или видео со спецпредложениями</p>",
				img: (
					<StaticImage
						className={styles.adImage}
						src="./assets/ad-feature.png"
						alt="меню электронной очереди"
						breakpoints={[420, 740, 920, 660]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 660px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.adFeature,
				theme: "cyan",
			},
			{
				category: "экран покупателя",
				title: "«Галя, у нас отмена!»",
				description: (
					<p className={styles.description3}>
						Подключи к&nbsp;кассе дисплей для гостей&nbsp;&mdash; так они увидят все
						позиции до&nbsp;того, как сотрудник пробьёт заказ. Туда не&nbsp;попадёт
						ничего лишнего, и&nbsp;кассир не&nbsp;будет тратить время на&nbsp;суету
						с&nbsp;исправлениями.
					</p>
				),
				img: (
					<StaticImage
						className={styles.screenImage}
						src="./assets/screen-feature_kz.png"
						alt="дисплей покупателя в кафе"
						breakpoints={[400, 700, 810, 568]}
						sizes="(max-width: 500px) 400px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.screenFeature,
				actionText: "Подробнее",
				actionLink: pagesLinks.customerDisplay.href,
				theme: "green",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "тапсырыстар мәртебесі",
				title: "Ретке келтіріңіз",
				description:
					"<p>Қызметкерлерге ванильді капучинл иегерін есептеудің қажеті жоқ: әсіресе қызып тұрған уақытта жарайды</p>",
				mediaContent: (
					<div className={styles.orderImageWrapper}>
						<StaticImage
							className={styles.orderImage}
							src="./assets/order-feature.png"
							alt="экран очереди"
							breakpoints={[320, 700, 810, 568]}
							sizes="(max-width: 520px) 300px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				className: styles.orderFeature,
				theme: "primary",
			},
			{
				category: "жарнамаға арналған орын",
				title: "Тағы бірдеңені ұсыныңыз",
				description:
					"<p>Экранда арнайы ұсыныстар бар бірнеше баннер немесе видеодан тұратын әткеншек үшін орын жетеді</p>",
				img: (
					<StaticImage
						className={styles.adImage}
						src="./assets/ad-feature.png"
						alt="меню электронной очереди"
						breakpoints={[420, 740, 920, 660]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 660px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.adFeature,
				theme: "cyan",
			},
			{
				category: "сатып алушының экраны",
				title: "«Галя, бізде тапсырыстан бас тарту!»",
				description: (
					<p className={styles.description3}>
						Кассаға қонақтар үшін дисплейді қосыңыз - осылайша олар қызметкер тапсырысты өткізгенге дейін барлық позицияларды көреді.
						Онда артық еш нәрсе түспейді, және кассир түзетіп әбігерленуге уақытын кетірмейді.
					</p>
				),
				img: (
					<StaticImage
						className={styles.screenImage}
						src="./assets/screen-feature_kz.png"
						alt="дисплей покупателя в кафе"
						breakpoints={[400, 700, 810, 568]}
						sizes="(max-width: 500px) 400px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.screenFeature,
				actionText: "Толығырақ",
				actionLink: pagesLinks.customerDisplay.href,
				theme: "green",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				category: "статус заказов",
				title: "Удобная очерёдность заказов",
				description:
					"<p>Сотрудникам не нужно вычислять обладателя ванильного капучино: особенно пригодится в пиковые часы</p>",
				mediaContent: (
					<div className={styles.orderImageWrapper}>
						<StaticImage
							className={styles.orderImage}
							src="./assets/order-feature.png"
							alt="экран очереди"
							breakpoints={[320, 700, 810, 568]}
							sizes="(max-width: 520px) 300px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				className: styles.orderFeature,
				theme: "primary",
			},
			{
				category: "место для рекламы",
				title: "Что ещё \nпонравится гостю",
				description:
					"<p>На экране хватит места для карусели из нескольких баннеров или видео со спецпредложениями</p>",
				img: (
					<StaticImage
						className={styles.adImage}
						src="./assets/ad-feature.png"
						alt="меню электронной очереди"
						breakpoints={[420, 740, 920, 660]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 660px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.adFeature,
				theme: "cyan",
			},
			{
				category: "экран покупателя",
				title: "«Галя, у нас отмена!»",
				description: (
					<p className={styles.description3}>
						Подключи к&nbsp;кассе дисплей для гостей&nbsp;&mdash; так они увидят все
						позиции до&nbsp;того, как сотрудник пробьёт заказ. Туда не&nbsp;попадёт
						ничего лишнего, и&nbsp;кассир не&nbsp;будет тратить время на&nbsp;суету
						с&nbsp;исправлениями.
					</p>
				),
				img: (
					<StaticImage
						className={styles.screenImage}
						src="./assets/screen-feature_by.png"
						alt="дисплей покупателя в кафе"
						breakpoints={[400, 700, 810, 568]}
						sizes="(max-width: 500px) 400px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.screenFeature,
				actionText: "Подробнее",
				actionLink: pagesLinks.customerDisplay.href,
				theme: "green",
			},
		]
	}

	return [
		{
			category: "статус заказов",
			title: "Наведи порядок",
			description:
				"<p>Сотрудникам не нужно вычислять обладателя ванильного капучино: особенно пригодится в пиковые часы</p>",
			mediaContent: (
				<div className={styles.orderImageWrapper}>
					<StaticImage
						className={styles.orderImage}
						src="./assets/order-feature.png"
						alt="экран очереди"
						breakpoints={[320, 700, 810, 568]}
						sizes="(max-width: 520px) 300px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			className: styles.orderFeature,
			theme: "primary",
		},
		{
			category: "место для рекламы",
			title: "Подскажи, что ещё можно купить",
			description:
				"<p>На экране хватит места для карусели из нескольких баннеров или видео со спецпредложениями</p>",
			img: (
				<StaticImage
					className={styles.adImage}
					src="./assets/ad-feature.png"
					alt="меню электронной очереди"
					breakpoints={[420, 740, 920, 660]}
					sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 660px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			reverse: true,
			className: styles.adFeature,
			theme: "cyan",
		},
		{
			category: "экран покупателя",
			title: "«Галя, у нас отмена!»",
			description: (
				<p className={styles.description3}>
					Подключи к&nbsp;кассе дисплей для гостей&nbsp;&mdash; так они увидят все
					позиции до&nbsp;того, как сотрудник пробьёт заказ. Туда не&nbsp;попадёт
					ничего лишнего, и&nbsp;кассир не&nbsp;будет тратить время на&nbsp;суету
					с&nbsp;исправлениями.
				</p>
			),
			img: (
				<StaticImage
					className={styles.screenImage}
					src="./assets/screen-feature.png"
					alt="дисплей покупателя в кафе"
					breakpoints={[400, 700, 810, 568]}
					sizes="(max-width: 500px) 400px, (max-width: 800px) 700px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.screenFeature,
			actionText: "Подробнее",
			actionLink: pagesLinks.customerDisplay.href,
			theme: "green",
		},
	]
}
