import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { externalLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Экран электронной очереди",
			desktopTitle: (
				<span>
			Заказ уже готов
			<br />
			или только готовится?
		</span>
			),
			tabletTitle: "Заказ уже готов или только готовится?",
			subTitle: (
				<p>
					Все ответы&nbsp;&mdash; на&nbsp;экране, сотрудники не&nbsp;отвлекаются от&nbsp;работы. А&nbsp;гости, пока
					ждут, могут присмотреть что-то ещё из&nbsp;акций и&nbsp;новинок меню.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/line-presentation_kz.png"
					alt="экран электронной очереди"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayLine,
					alt: "Google Play Market",
				},
			},
			subTitleClass: styles.subtitle,
		}
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Электрондық кезек экраны",
			desktopTitle: (
				<span>
			Тапсырыс дайын ба әлде <br />енді дайындалып жатыр ма?
		</span>
			),
			tabletTitle: "Тапсырыс дайын ба әлде енді дайындалып жатыр ма?",
			subTitle: (
				<p>
					Барлық жауаптар - экранда, қызметкерлер жұмысқа енжар болмайды. Ал қонақтар күтіп тұрғанда акциялар
					мен мәзір жаңалықтарынан тағы бірдеңені қарап таңдай алады.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/line-presentation_kz.png"
					alt="экран электронной очереди"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayLine,
					alt: "Google Play Market",
				},
			},
			subTitleClass: styles.subtitle,
		}
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Экран электронной очереди",
			desktopTitle: (
				<span>
					Что с заказом?
				</span>
			),
			tabletTitle: "Что с заказом?",
			subTitle: (
				<p>
					Все ответы&nbsp;&mdash; на&nbsp;экране, сотрудники не&nbsp;отвлекаются от&nbsp;работы. А&nbsp;гости, пока
					ждут, могут присмотреть что-то ещё из&nbsp;акций и&nbsp;новинок меню.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/line-presentation_by.png"
					alt="экран электронной очереди"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayLine,
					alt: "Google Play Market",
				},
			},
			subTitleClass: styles.subtitle,
		}
	}

	return {
		pageName: "Экран электронной очереди",
		desktopTitle: (
			<span>
			Заказ уже готов
			<br />
			или только готовится?
		</span>
		),
		tabletTitle: "Заказ уже готов или только готовится?",
		subTitle: (
			<p>
				Все ответы&nbsp;&mdash; на&nbsp;экране, сотрудники не&nbsp;отвлекаются
				от&nbsp;работы. А&nbsp;гости, пока ждут, могут присмотреть что-то ещё
				из&nbsp;акций и&nbsp;новинок меню.
			</p>
		),
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/line-presentation.png"
				alt="экран электронной очереди"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={90}
			/>
		),
		storeBadges: {
			googleplay: {
				href: externalLinks.googlePlayLine,
				alt: "Google Play Market",
			},
		},
		subTitleClass: "",
	}
}
