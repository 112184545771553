import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/line/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import Features from '../pagesSections/line/Features'
import { getLineMeta } from '../pagesSections/line/meta'

import { getAdvantagesSlides } from '../pages-data/_V2/line/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/line.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import { getSeoText } from "../pages-data/_V2/line/seotext";
import SeoText from "../components/_V2/SeoText";

export default function PageLine() {
  const localizationContext = useLocalizationContext()
  const metaInfo = getLineMeta(localizationContext.locale)

  return (
    <>
      <DefaultLayout
        key={localizationContext.locale}
        title={metaInfo.title}
        metaTags={metaInfo.metaTags}
        linkCanonical={metaInfo.canonical}
        footerClassName={pageStyles.pageSection}
        schemaType="Product"
        schemaName={metaInfo.title}
        schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
        schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
      >
        <ProductPresentation />

        <Advantages
          className={classNames(
					  styles.line__advantages,
					  pageStyles.pageSection,
          )}
          items={getAdvantagesSlides(localizationContext.locale)}
        />

        <Features className={pageStyles.pageSection} />

        <AdditionalServices className={pageStyles.pageSection} />

        <CtaForm isFry={false} className={pageStyles.pageSection} />

        <BusinessTypes
          key={localizationContext.locale}
          data={businessTypes(localizationContext.locale)}
          className={pageStyles.pageSection}
        />

        <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
        <SeoText
            className={pageStyles.pageSection}
            data={getSeoText()}
        />
      </DefaultLayout>
      {/* <main className={pageStyles.pageWrapper}> */}

      {/* 	/!* Обратный звонок сервиса "Mango" *!/ */}
      {/* 	<div */}
      {/* 		className="mango-callback" */}
      {/* 		data-settings='{"type":"", "id": "MTAwMDI1NjY=","autoDial" : "0", "lang" : "ru-ru", "host":"lk.mango-office.ru/", "errorMessage": "В данный момент наблюдаются технические проблемы и совершение звонка невозможно"}' */}
      {/* 	> */}
      {/* 		<button */}
      {/* 			className="button-widget-open" */}
      {/* 			id="mango-widget-btn" */}
      {/* 			style={{ display: "none" }} */}
      {/* 		> */}
      {/* 			☎ ️ */}
      {/* 		</button> */}
      {/* 	</div> */}
      {/* </main> */}
    </>
  )
}
