import React from "react"
import SimpleFeatures from "../../../components/_V2/SimpleFeatures"

import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function Features(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<SimpleFeatures
			key={localizationContext.locale}
			data={getData(localizationContext.locale)}
			className={props.className}
		/>
	)
}
