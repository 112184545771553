import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				title: "Оңай орнату",
				description: (
					<>
						Android негізіндегі планшеттерге және Android TV негізіндегі теледидарларға қосылады.
					</>
				),
			},
			{
				title: "Бәрі бір орында",
				description:
					"Залдан, қосымшадан және сайттан келіп түсетін тапсырыстарды қиыстырамыз: қонақтың тапсырысты қайда ресімдегені маңызды емес.",
			},
			{
				title: "Ыстықтай",
				description:
					"Тапсырыс қонаққа уақытында, жаңа піскен және ыстық болып келеді - қонақ риза.",
			},
		]
	}

	return [
		{
			title: "Простая установка",
			description: (
				<>
					Подключается к&nbsp;планшетам
					<br />
					на&nbsp;базе Android и&nbsp;телевизорам
					на&nbsp;базе&nbsp;Android&nbsp;TV.
				</>
			),
		},
		{
			title: "Всё в одном месте",
			description:
				"Подружим заказы из зала, приложения и с сайта: неважно, где гость оформил заказ.",
		},
		{
			title: "С пылу с жару",
			description:
				"Заказ попадает к гостю вовремя, свежим и горячим — гость доволен.",
		},
	]
}

export default [
	{
		title: "Простая установка",
		description: (
			<>
				Подключается к&nbsp;планшетам
				<br />
				на&nbsp;базе Android и&nbsp;телевизорам
				на&nbsp;базе&nbsp;Android&nbsp;TV.
			</>
		),
	},
	{
		title: "Всё в одном месте",
		description:
			"Подружим заказы из зала, приложения и с сайта: неважно, где гость оформил заказ.",
	},
	{
		title: "С пылу с жару",
		description:
			"Заказ попадает к гостю вовремя, свежим и горячим — гость доволен.",
	},
]
