import React from "react"
import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"
import { getData } from "./data"
import Backdrop from "./assets/Backdrop"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

export default function ProductPresentation() {
	const localizationContext = useLocalizationContext()

	return (
		<ProductPresentationBase
			key={localizationContext.locale}
			sectionClass={styles.section}
			containerClass={styles.content}
			data={getData(localizationContext.locale)}
			backdrop={Backdrop}
			backdropClass={styles.backdrop}
			additionalDescriptionClass={getData(localizationContext.locale).subTitleClass}
		/>
	)
}
