import React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="1077"
		height="448"
		viewBox="0 0 1077 448"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xlinkHref="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1077 448V0H0C125.903 308.121 827.276 448 1077 448Z"
			fill="url(#pattern0)"
			fillOpacity="0.2"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0185701"
				height="0.0457589"
			>
				<use
					xlinkHref="#image0_3057_47557"
					transform="scale(0.000464253 0.00111607)"
				/>
			</pattern>
			<image
				id="image0_3057_47557"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop
