import { Locales } from "../../../localization/types";
import PresentationImg from "../ProductPresentation/assets/line-presentation.png";
import PresentationImgKz from "../ProductPresentation/assets/line-presentation_kz.png";
import PresentationImgBy from "../ProductPresentation/assets/line-presentation_by.png";
import { externalLinks } from "../../../pages-data/_V2/common/links";

export const getLineMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Экран электронной очереди заказов в общепите Quick Resto Line | Казахстан',
			metaTags: [
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					name: "viewport",
					content: "width=375, maximum-scale=1.0, initial-scale=1",
				},
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Экран электронной очереди (queue management system) для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса готовности заказа ✔ Уменьшение нагрузки на сотрудников без потери скорости. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:description",
					content:
						"Экран электронной очереди (queue management system) для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса готовности заказа ✔ Уменьшение нагрузки на сотрудников без потери скорости. Автоматизируй бизнес в Казахстане",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoKZ.href}line/`,
				},
				{
					property: "og:title",
					content: "Экран электронной очереди заказов в общепите Quick Resto Line | Казахстан",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoKZ.href}${PresentationImgKz}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}line/`,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Экран электронной очереди заказов в общепите Quick Resto Line | Беларусь',
			metaTags: [
				{
					name: "viewport",
					content: "width=device-width, initial-scale=1, shrink-to-fit=no",
				},
				{
					name: "viewport",
					content: "width=375, maximum-scale=1.0, initial-scale=1",
				},
				{
					httpEquiv: "X-UA-Compatible",
					content: "ie=edge",
				},
				{
					name: "description",
					content:
						"Экран электронной очереди (queue management system) для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса готовности заказа ✔ Уменьшение нагрузки на сотрудников без потери скорости. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:description",
					content:
						"Экран электронной очереди (queue management system) для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса готовности заказа ✔ Уменьшение нагрузки на сотрудников без потери скорости. Автоматизируй бизнес в Беларуси",
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					property: "og:url",
					content: `${externalLinks.quickrestoBY.href}line/`,
				},
				{
					property: "og:title",
					content: "Экран электронной очереди заказов в общепите Quick Resto Line | Беларусь",
				},
				{
					property: "og:image",
					content: `${externalLinks.quickrestoBY.href}${PresentationImgBy}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}line/`,
		}
	}

	return {
		title: 'Экран электронной очереди заказов фаст-фуда Quick Resto Line',
		metaTags: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1, shrink-to-fit=no",
			},
			{
				name: "viewport",
				content: "width=375, maximum-scale=1.0, initial-scale=1",
			},
			{
				httpEquiv: "X-UA-Compatible",
				content: "ie=edge",
			},
			{
				name: "description",
				content:
					"Экран электронной очереди для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса заказа ✔ Снижение нагрузки на сотрудников ✔ Повышение скорости обслуживания.",
			},
			{
				property: "og:description",
				content:
					"Экран электронной очереди для пиццерий, фаст-фуда и формата с собой ✔ Отслеживание статуса заказа ✔ Снижение нагрузки на сотрудников ✔ Повышение скорости обслуживания.",
			},
			{
				property: "og:type",
				content: "website",
			},
			{
				property: "og:url",
				content: `${externalLinks.quickrestoRU.href}line/`,
			},
			{
				property: "og:title",
				content: "Экран электронной очереди заказов фаст-фуда Quick Resto Line",
			},
			{
				property: "og:image",
				content: `${externalLinks.quickrestoRU.href}${PresentationImg}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}line/`,
	}
}
